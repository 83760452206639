@media (max-width: 960px) {
  .nav {
    padding: 20px 0;
    width: 100%;
    & a {
      font-size: 14px;
    }
    background: rgba(#ddd, 0.8);
    backdrop-filter: blur(10px);
  }

  .hero {
    width: 100%;
  }
  .hero__img {
    padding: 0 48px;
    width: 100%;
  }
  .hero__arrow {
    zoom: 0.6;
  }
  .overview__is {
    padding: 24px;
    width: 100%;
  }

  .points {
    padding: 0 24px;
    display: block;
    width: 100%;
  }
  .point {
    width: 100%;
    margin-bottom: 24px;
  }
  .point__title {
    border-top: 0;
  }
  .point__desc {
    margin-top: 24px;
  }

  .fullsep {
    margin: 64px 0;
  }

  .button,
  .chapter__learn {
    height: 48px;
    font-size: 16px;
  }

  .chapter {
    padding: 0 24px 64px 24px;
  }
  .chapter__title {
    font-size: 48px;
  }
  .chapter__desc {
    margin-top: 24px;
    font-size: 16px;
    line-height: 1.5;
  }

  .overview__vid {
    padding: 24px 16px;
  }
  .vid {
    width: 100%;
  }

  .nero {
    width: 100%;
  }
  .nero__title {
    width: 100%;
    font-size: 54px;
    text-align: center;
  }
  .nero__caption {
    margin-top: 24px;
    padding: 0 24px;
    width: 100%;
    font-size: 18px;
  }
  .section {
    overflow: hidden;
    margin-top: 48px;
    padding: 0 24px;
    width: 100%;
    & img {
      width: 100%;
    }
    & video,
    .vid {
      width: 100% !important;
    }
    &.left,
    &.right {
      flex-direction: column;
      & video,
      img {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .section__desc {
    margin-top: 24px;
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 1.5;
  }

  .credits {
    margin-top: 100px;
    height: auto;
    padding: 0 24px;
    margin-bottom: 100px;
    width: 100%;
  }
  .credits__title {
    font-size: 32px;
  }
  footer {
    margin-top: 64px;
    padding: 0 24px;
  }
  .footer__heading {
    padding-bottom: 12px;
  }
  .footer__content {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    & > div {
      margin-bottom: 32px;
    }
  }

  .arch-modules__img {
    width: auto !important;
    height: 232px;
  }
  .arch-modules__desc {
    transform: none;
    font-size: 16px;
    line-height: 1.5;
  }
}
