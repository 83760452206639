@import './reset.scss';

@font-face {
  font-family: 'Söhne';
  src: url('./fonts/SohneWeb-Mager.woff2') format('woff2');
}
@font-face {
  font-family: 'Söhne';
  font-weight: 500;
  src: url('./fonts/SohneWeb-Kräftig.woff2') format('woff2');
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$black: #333;
$navy: #a3a9b1;
html {
  color: $black;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Söhne', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  //   Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #ddd;
  &.overview {
    background-image: linear-gradient(
      180deg,
      #c7cad0 0%,
      rgba(203, 194, 194, 0) 1500px
    );
  }
}

// Animations
@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

::selection {
  color: $black;
  background: #c7cad0;
}

// Navigation
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.nav {
  margin: 0 auto;
  padding: 35px 0;
  width: 680px;
  display: flex;
  justify-content: space-around;
  & a {
    opacity: 0.5;
    color: $black;
    text-decoration: none;
    &.selected {
      opacity: 1;
    }
  }
}
b {
  font-weight: 500;
}

// Hero
.hero {
  margin: 0 auto;
  width: 900px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hero__img {
  margin-top: auto;
}
.hero__caption {
  opacity: 0.8;
  margin-top: 120px;
  font-size: 32px;
  line-height: 1.4;
  text-align: center;
}
.hero__arrow {
  margin-top: auto;
  margin-bottom: 64px;
  animation: updown 3s ease infinite;
}

// Nero
.nero {
  @extend .hero;
}
.nero__title {
  font-size: 120px;
  letter-spacing: -0.02em;
}
.nero__caption {
  opacity: 0.8;
  margin-top: 120px;
  font-size: 32px;
  line-height: 1.4;
  text-align: center;
}

// Overview page
.overview__is {
  margin: 0 auto;
  display: block;
  padding-bottom: 120px;
}
.overview__vid {
  padding: 120px 0;
}

// Points on overview page
.points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  margin: 0 auto;
  width: 900px;
}
.point {
  width: 100%;
}
.point__title {
  padding-top: 16px;
  border-top: 4px solid rgba($navy, 0.15);
  font-size: 32px;
  font-weight: 500;
}
.point__desc {
  margin-top: 48px;
  font-size: 18px;
  line-height: 24px;
  & a {
    display: inline-flex;
    align-items: center;
    color: $black;
    text-decoration: none;
    & img {
      margin-left: 4px;
      transition: 0.2s ease all;
    }
    &:hover {
      & img {
        transform: translateX(3px);
      }
    }
  }
}

// Video component
.vid {
  position: relative;
  margin: 0 auto;
  width: 900px;
  & video {
    padding: 3%;
    width: 100%;
  }
}
.vid__frame {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: url(../assets/pad-noshadow.png) center center no-repeat;
  background-size: contain;
}

// Section
.section {
  margin: 0 auto;
  padding-bottom: 104px;
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  border-top: 2px solid rgba($navy, 0.15);
  &.right,
  &.left {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    & img {
      margin-top: 48px;
      padding-left: 64px;
      // max-width: 541px;
    }
    & video {
      margin-top: 48px;
      object-position: top;
    }
    & .section__desc {
      font-size: 16px;
      line-height: 24px;
      white-space: pre-wrap;
    }
  }
  &.left {
    & img {
      padding-right: 64px;
    }
    & video {
      padding-right: 64px;
    }
    flex-direction: row-reverse;
  }
}
.section__title {
  margin-top: 48px;
  font-size: 48px;
  font-weight: 500;
  &--sub {
    font-size: 32px;
  }
  &--nochildren {
    & + .section__desc {
      margin-bottom: 0 !important;
    }
  }
}
.section__desc {
  margin-top: 40px;
  margin-bottom: 72px;
  font-size: 22px;
  line-height: 32px;
  white-space: pre-wrap;
}
.section-image {
  // margin: 120px 0 120px 0;
}
.section__caption {
  margin: 48px auto 0 auto;
  max-width: 450px;
  line-height: 24px;
  text-align: center;
}

// Full-width sep
.fullsep {
  margin: 120px 0;
  width: 100%;
  height: 4px;
  background: rgba($navy, 0.15);
}

// Button
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 48px;
  height: 72px;
  font-size: 24px;
  border-radius: 200px;
  color: $black;
  background: rgba(#fff, 0.25);
  text-decoration: none;
}

// Chapter
.chapter {
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.chapter__title {
  font-size: 72px;
  color: rgba(#333, 0.25);
}
.chapter__desc {
  margin-top: 64px;
  max-width: 640px;
  color: #333;
  font-size: 24px;
  line-height: 36px;
}
.chapter__learn {
  margin-top: 40px;
  cursor: pointer;
  padding: 0 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $black;
  font-size: 24px;
  height: 72px;
  background: rgba(#fff, 0.25);
  border-radius: 100px;
  text-decoration: none;
}

.credits {
  margin: 0 auto;
  width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.credits__title {
  padding-bottom: 60px;
  font-weight: 500;
  font-size: 48px;
}
.credits__desc {
  line-height: 1.5;
  & p {
    margin: 1em 0;
  }
}

// Arch page
.arch-modules__desc {
  font-size: 22px;
  line-height: 32px;
  transform: translateY(-100px);
}

// Footer
footer {
  padding-top: 24px;
  padding-bottom: 300px;
  width: 100%;
  font-size: 14px;
  background: #333;
}
.footer__content {
  margin: 0 auto;
  width: 100%;
  display: grid;
  max-width: 900px;
  grid-template-columns: repeat(6, 100px);
  grid-column-gap: 60px;
}
.footer__links {
  grid-column: 1 / 1;
}
.footer__about {
  grid-column: 2 / 5;
  & a {
    color: #ddd;
    text-decoration: underline;
  }
}
.footer__credits {
  grid-column: 5 / span 2;
}
.footer__heading {
  padding-bottom: 34px;
  padding-top: 16px;
  color: #ddd;
  font-weight: 500;
}
.footer__nav {
  display: flex;
  flex-direction: column;
  & a {
    color: #ddd;
    text-decoration: none;
    line-height: 24px;
  }
}
.footer__p {
  color: #ddd;
  line-height: 24px;
  & a {
    color: #ddd;
    text-decoration: none;
  }
}

@import './mobile.scss';
